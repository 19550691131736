import React from "react";
import styled from "@emotion/styled";
import Image from "react-bootstrap/esm/Image";
import Button from "react-bootstrap/esm/Button";
import { FormSelectStyled, GhostButton, RowContainer } from "./Common.styled";

const Icon = styled(Image)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const StatusIcon = styled(Image)`
  width: 40px;
  height: 40px;
  margin: 5px 5px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.1)};
  cursor: pointer;
`;

export const ActionButton = styled(Button)`
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 1.5em;
  ${({ isLeft, isRight }) => `
    ${isLeft ? `margin-right: 8px;` : ""}
    ${isRight ? `margin-left: 8px;` : ""}
  `}
  & > svg {
    min-height: 24px;
    min-width: 24px;
  }
`;

const LeftArrow = (props) => (
  <ActionButton variant="outline-secondary" isLeft={true} size="sm" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="bi bi-arrow-left"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
      />
    </svg>
  </ActionButton>
);

const RightArrow = (props) => (
  <ActionButton variant="outline-secondary" isRight={true} size="sm" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="bi bi-arrow-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
      />
    </svg>
  </ActionButton>
);

export const GameId = ({ gameId }) => {
  return <ActionButton variant="outline-dark">{gameId}</ActionButton>;
};

export const SelectGameId = ({ idsList, onGameIdChange }) => {
  const handleChange = (e) => onGameIdChange(parseInt(e.target.value));
  return (
    <>
      <FormSelectStyled aria-label="id selection" onChange={handleChange}>
        <option value="">-- Select Id --</option>
        {idsList.map((id) => (
          <option key={id} value={id}>
            {id}
          </option>
        ))}
      </FormSelectStyled>
    </>
  );
};

const HealthContainer = styled.div`
  display: flex;
  position: relative;
  > img {
    width: 30px;
    height: 40px;
    object-fit: cover;
    opacity: 50%;
  }
  > div {
    font-size: 1.5em;
    position: absolute;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }
`;

export const Health = ({ health, onIncreaseHealth, onDecreaseHealth }) => (
  <>
    <LeftArrow onClick={onDecreaseHealth} />
    <HealthContainer>
      <Image src="/assets/utils/health.svg" /> <div>{health}</div>
    </HealthContainer>
    <RightArrow onClick={onIncreaseHealth} />
  </>
);

export const Status = ({ name, isActive, onToggleStatus, imgName }) => {
  return (
    <StatusIcon
      isActive={isActive}
      src={`/assets/${imgName || `status/${name}.svg`}`}
      onClick={onToggleStatus}
      alt={name}
      title={name}
    />
  );
};

export const Properties = ({ title, properties }) =>
  properties?.length > 0 && (
    <RowContainer>
      {title ? <>{title} : </> : null}
      <RowContainer>
        {properties.map((att) => (
          <div key={att.name}>
            {att.image && <Icon src={att.image} />}
            {att.name}
            {att.value && `: ${att.value}`}
          </div>
        ))}
      </RowContainer>
      <hr />
    </RowContainer>
  );

export const BaseStats = ({ health, attack, move, range }) => (
  <RowContainer>
    <div>
      <Icon src="/assets/utils/health.svg" /> {health}
    </div>
    <div>
      <Icon src="/assets/utils/attack.svg" /> {attack}
    </div>
    <div>
      <Icon src={move.image} /> {move.value}
    </div>
    <div>
      <Icon src="/assets/utils/range.svg" /> {range}
    </div>
  </RowContainer>
);

export const ToggleButton = ({ onClick }) => {
  return (
    <GhostButton variant="outline-dark" size="sm" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-chevron-down"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </GhostButton>
  );
};
