import { createContext, useContext } from "react";
import { useMonsterSocket } from "./useMonsterSocket";

const MonsterContext = createContext(null);

export const MonsterContextProvider = ({ roomId, children }) => (
  <MonsterContext.Provider value={useMonsterSocket(roomId)}>
    {children}
  </MonsterContext.Provider>
);

export const useMonsterContext = () => useContext(MonsterContext);
