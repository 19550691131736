import { useCallback, useReducer } from "react";

const ACTION_TYPES = {
  ADD_MONSTER_TYPE: "ADD_MONSTER_TYPE",
  REMOVE_MONSTER_TYPE: "REMOVE_MONSTER_TYPE",
  ADD_MONSTER: "ADD_MONSTER",
  ADD_BOSS: "ADD_BOSS",
  REMOVE_BOSS: "REMOVE_BOSS",
  INCREASE_BOSS_HEALTH: "INCREASE_BOSS_HEALTH",
  DECREASE_BOSS_HEALTH: "DECREASE_BOSS_HEALTH",
  TOGGLE_BOSS_STATUS: "TOGGLE_BOSS_STATUS",
  REMOVE_MONSTER: "REMOVE_MONSTER",
  INCREASE_MONSTER_HEALTH: "INCREASE_MONSTER_HEALTH",
  DECREASE_MONSTER_HEALTH: "DECREASE_MONSTER_HEALTH",
  INCREASE_MONSTER_GAME_ID: "INCREASE_MONSTER_GAME_ID",
  DECREASE_MONSTER_GAME_ID: "DECREASE_MONSTER_GAME_ID",
  CHANGE_MONSTER_GAME_ID: "CHANGE_MONSTER_GAME_ID",
  TOGGLE_MONSTER_STATUS: "TOGGLE_MONSTER_STATUS",
  NEW_STATE: "NEW_STATE",
};

const bossFactory = (boss) => ({
  id: boss.name,
  name: boss.name,
  image: boss.image,
  portrait: boss.portrait,
  health: boss.health,
  maxHealth: boss.maxHealth,
  attack: boss.attack,
  move: boss.move,
  range: boss.range,
  specials: boss.specials,
  immunities: boss.immunities,
  attributes: boss.attributes,
  status: boss.status ? new Set(boss.status.map((s) => s.name)) : new Set(),
});

const monsterFactory = (monster, gameId) => ({
  id: monster.id || gameId,
  gameId: monster.id ?? gameId ?? 1,
  health: monster.health,
  maxHealth: monster.maxHealth,
  status: monster.status
    ? new Set(monster.status.map((s) => s.name))
    : new Set(),
});

const monsterTypeFactory = (monsterType) => ({
  id: monsterType.id || `${monsterType.name}-${monsterType.elite}`,
  attack: monsterType.attack,
  attributes: monsterType.attributes,
  health: monsterType.health || monsterType.maxHealth,
  image: monsterType.image,
  portrait: monsterType.portrait,
  move: monsterType.move,
  name: monsterType.name,
  range: monsterType.range,
  level: monsterType.level ?? null,
  elite: Boolean(monsterType.elite),
  possibleIDs: monsterType.possibleIDs || [],
  monsters: monsterType.monsters
    ? monsterType.monsters.map((m) => monsterFactory(m))
    : [monsterFactory(monsterType)],
});

const isEqualMonster = (monster, otherMonster) =>
  monster.name === otherMonster.name && monster.elite === otherMonster.elite;

export const getMonsterType = (monsterTypes, monsterTypeId) => {
  return monsterTypes.find((mt) => mt.id === monsterTypeId);
};

export const getMonster = (monsterTypes, monsterTypeId, monsterId) => {
  const mt = getMonsterType(monsterTypes, monsterTypeId);
  return mt?.monsters.find((m) => m.id === monsterId);
};

export const getBoss = (bosses, bossId) => {
  return bosses.find((b) => b.id === bossId);
};

const replaceBoss = (bosses, newBoss) =>
  bosses.map((b) => (b.id !== newBoss.id ? b : newBoss));

const replaceMonster = (monsterTypes, monsterTypeId, newMonster) =>
  monsterTypes.map((mt) =>
    mt.id !== monsterTypeId
      ? mt
      : {
          ...mt,
          monsters: mt.monsters.map((m) =>
            m.id !== newMonster.id ? m : newMonster
          ),
        }
  );

export const stateToJson = (state) => {
  return {
    ...state,
    monsterTypes: state.monsterTypes.map((mt) => ({
      ...mt,
      monsters: mt.monsters.map((m) => ({
        ...m,
        status: [...m.status.values()],
      })),
    })),
    bosses: state.bosses.map((b) => ({
      ...b,
      status: [...b.status.values()],
    })),
  };
};

export const jsonToState = (state) => {
  return {
    ...state,
    monsterTypes: state.monsterTypes?.map((mt) => monsterTypeFactory(mt)) ?? [],
    bosses: state.bosses.map((b) => bossFactory(b)),
  };
};

export const baseState = {
  id: "base-id",
  monsterTypes: [],
  bosses: [],
};

export const monsterReducer = (state = baseState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.ADD_BOSS: {
      const { newBoss } = payload;
      const { bosses } = state;

      const newBossState = !bosses.some((b) => b.id === newBoss.id)
        ? bossFactory(newBoss)
        : null;
      if (!newBossState) return state;

      return {
        ...state,
        bosses: [...state.bosses, newBossState].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    }
    case ACTION_TYPES.REMOVE_BOSS:
      return {
        ...state,
        bosses: state.bosses.filter((b) => b.id !== payload),
      };
    case ACTION_TYPES.ADD_MONSTER_TYPE: {
      const { newMonsterType, elite } = payload;
      const { monsterTypes } = state;

      newMonsterType.elite = newMonsterType.elite ?? elite;
      const newMonsterTypeState = !monsterTypes.some((m) =>
        isEqualMonster(m, newMonsterType)
      )
        ? monsterTypeFactory(newMonsterType)
        : null;
      if (!newMonsterTypeState) return state;

      return {
        ...state,
        monsterTypes: [...state.monsterTypes, newMonsterTypeState].sort(
          (a, b) => a.name.localeCompare(b.name)
        ),
      };
    }
    case ACTION_TYPES.REMOVE_MONSTER_TYPE:
      return {
        ...state,
        monsterTypes: state.monsterTypes.filter((m) => m.id !== payload),
      };

    case ACTION_TYPES.ADD_MONSTER:
      const { monsterTypeId, id } = payload;
      const monsterType = state.monsterTypes.find(
        (m) => m.id === monsterTypeId
      );
      if (!monsterType) {
        return state;
      }
      const newMonsterTypeState = {
        ...monsterType,
        monsters: [...monsterType.monsters, monsterFactory(monsterType, id)],
      };
      return {
        ...state,
        monsterTypes: state.monsterTypes.map((mt) =>
          mt.id !== payload ? mt : newMonsterTypeState
        ),
      };

    case ACTION_TYPES.REMOVE_MONSTER: {
      const { monsterTypeId, monsterId } = payload;
      return {
        ...state,
        monsterTypes: state.monsterTypes.map((mt) =>
          mt.id !== monsterTypeId
            ? mt
            : {
                ...mt,
                monsters: mt.monsters.filter((m) => m.id !== monsterId),
              }
        ),
      };
    }

    case ACTION_TYPES.INCREASE_BOSS_HEALTH: {
      const { bossId } = payload;
      const boss = state.bosses.find((b) => b.id === bossId);
      if (!boss) return state;
      const newBossState = {
        ...boss,
        health: boss.health < boss.maxHealth ? boss.health + 1 : boss.maxHealth,
      };
      return {
        ...state,
        bosses: replaceBoss(state.bosses, newBossState),
      };
    }

    case ACTION_TYPES.INCREASE_MONSTER_HEALTH: {
      const { monsterTypeId, monsterId } = payload;
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (!monster) return state;
      const newMonsterState = {
        ...monster,
        health:
          monster.health < monster.maxHealth
            ? monster.health + 1
            : monster.maxHealth,
      };
      return {
        ...state,
        monsterTypes: replaceMonster(
          state.monsterTypes,
          monsterTypeId,
          newMonsterState
        ),
      };
    }

    case ACTION_TYPES.DECREASE_MONSTER_HEALTH: {
      const { monsterTypeId, monsterId } = payload;
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (!monster) return state;
      const newMonsterState = {
        ...monster,
        health: monster.health > 0 ? monster.health - 1 : 0,
      };
      return {
        ...state,
        monsterTypes: replaceMonster(
          state.monsterTypes,
          monsterTypeId,
          newMonsterState
        ),
      };
    }

    case ACTION_TYPES.DECREASE_BOSS_HEALTH: {
      const { bossId } = payload;
      const boss = state.bosses.find((b) => b.id === bossId);
      if (!boss) return state;
      const newBossState = {
        ...boss,
        health: boss.health > 0 ? boss.health - 1 : 0,
      };
      return {
        ...state,
        bosses: replaceBoss(state.bosses, newBossState),
      };
    }

    case ACTION_TYPES.INCREASE_MONSTER_GAME_ID: {
      const { monsterTypeId, monsterId } = payload;
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (!monster) return state;
      const newMonsterState = {
        ...monster,
        gameId: monster.gameId + 1,
      };
      return {
        ...state,
        monsterTypes: replaceMonster(
          state.monsterTypes,
          monsterTypeId,
          newMonsterState
        ),
      };
    }

    case ACTION_TYPES.DECREASE_MONSTER_GAME_ID: {
      const { monsterTypeId, monsterId } = payload;
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (!monster) return state;
      const newMonsterState = {
        ...monster,
        gameId: monster.gameId > 1 ? monster.gameId - 1 : 1,
      };
      return {
        ...state,
        monsterTypes: replaceMonster(
          state.monsterTypes,
          monsterTypeId,
          newMonsterState
        ),
      };
    }

    case ACTION_TYPES.CHANGE_MONSTER_GAME_ID: {
      const { monsterTypeId, monsterId, newGameId } = payload;
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (!monster) return state;
      const newMonsterState = {
        ...monster,
        gameId: newGameId,
      };
      return {
        ...state,
        monsterTypes: replaceMonster(
          state.monsterTypes,
          monsterTypeId,
          newMonsterState
        ),
      };
    }

    case ACTION_TYPES.TOGGLE_MONSTER_STATUS: {
      const { monsterTypeId, monsterId, statusName } = payload;
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (!monster) return state;
      const status = new Set(monster.status);
      if (!status.has(statusName)) {
        status.add(statusName);
      } else {
        status.delete(statusName);
      }
      const newMonsterState = {
        ...monster,
        status: status,
      };
      return {
        ...state,
        monsterTypes: replaceMonster(
          state.monsterTypes,
          monsterTypeId,
          newMonsterState
        ),
      };
    }

    case ACTION_TYPES.TOGGLE_BOSS_STATUS: {
      const { bossId, statusName } = payload;
      const boss = state.bosses.find((b) => b.id === bossId);
      if (!boss) return state;
      const status = new Set(boss.status);
      if (!status.has(statusName)) {
        status.add(statusName);
      } else {
        status.delete(statusName);
      }
      const newBossState = {
        ...boss,
        status: status,
      };
      return {
        ...state,
        bosses: replaceBoss(state.bosses, newBossState),
      };
    }
    case ACTION_TYPES.NEW_STATE: {
      return { ...payload };
    }

    default:
      return state;
  }
};

const useMonster = (initialState) => {
  const [state, dispatch] = useReducer(
    monsterReducer,
    initialState || baseState
  );

  // useEffect(() => storeState(state), [state]);

  const addMonsterType = useCallback((newMonsterType, elite) => {
    dispatch({
      type: ACTION_TYPES.ADD_MONSTER_TYPE,
      payload: { newMonsterType, elite },
    });
  }, []);
  const removeMonsterType = useCallback((id) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_MONSTER_TYPE,
      payload: id,
    });
  }, []);
  const addMonster = useCallback((monsterTypeId, id) => {
    dispatch({
      type: ACTION_TYPES.ADD_MONSTER,
      payload: { monsterTypeId, id },
    });
  }, []);
  const removeMonster = useCallback((monsterTypeId, monsterId) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_MONSTER,
      payload: { monsterTypeId, monsterId },
    });
  }, []);
  const addBoss = useCallback((newBoss) => {
    dispatch({
      type: ACTION_TYPES.ADD_BOSS,
      payload: { newBoss },
    });
  }, []);
  const removeBoss = useCallback((bossId) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_BOSS,
      payload: bossId,
    });
  }, []);
  const increaseMonsterHealth = useCallback((monsterTypeId, monsterId) => {
    dispatch({
      type: ACTION_TYPES.INCREASE_MONSTER_HEALTH,
      payload: { monsterTypeId, monsterId },
    });
  }, []);
  const decreaseMonsterHealth = useCallback((monsterTypeId, monsterId) => {
    dispatch({
      type: ACTION_TYPES.DECREASE_MONSTER_HEALTH,
      payload: { monsterTypeId, monsterId },
    });
  }, []);
  const increaseBossHealth = useCallback((bossId) => {
    dispatch({
      type: ACTION_TYPES.INCREASE_BOSS_HEALTH,
      payload: { bossId },
    });
  }, []);
  const decreaseBossHealth = useCallback((bossId) => {
    dispatch({
      type: ACTION_TYPES.DECREASE_BOSS_HEALTH,
      payload: { bossId },
    });
  }, []);
  const increaseMonsterGameId = useCallback((monsterTypeId, monsterId) => {
    dispatch({
      type: ACTION_TYPES.INCREASE_MONSTER_GAME_ID,
      payload: { monsterTypeId, monsterId },
    });
  }, []);
  const decreaseMonsterGameId = useCallback((monsterTypeId, monsterId) => {
    dispatch({
      type: ACTION_TYPES.DECREASE_MONSTER_GAME_ID,
      payload: { monsterTypeId, monsterId },
    });
  }, []);
  const changeMonsterGameId = useCallback(
    (monsterTypeId, monsterId, newGameId) => {
      dispatch({
        type: ACTION_TYPES.CHANGE_MONSTER_GAME_ID,
        payload: { monsterTypeId, monsterId, newGameId },
      });
    },
    []
  );
  const toggleMonsterStatus = useCallback(
    (monsterTypeId, monsterId, statusName) => {
      dispatch({
        type: ACTION_TYPES.TOGGLE_MONSTER_STATUS,
        payload: { monsterTypeId, monsterId, statusName },
      });
    },
    []
  );
  const toggleBossStatus = useCallback((bossId, statusName) => {
    dispatch({
      type: ACTION_TYPES.TOGGLE_BOSS_STATUS,
      payload: { bossId, statusName },
    });
  }, []);

  const newState = useCallback((newState) => {
    dispatch({
      type: ACTION_TYPES.NEW_STATE,
      payload: newState,
    });
  }, []);

  return {
    state,
    addMonsterType,
    removeMonsterType,
    addMonster,
    removeMonster,
    increaseMonsterHealth,
    decreaseMonsterHealth,
    increaseMonsterGameId,
    decreaseMonsterGameId,
    changeMonsterGameId,
    toggleMonsterStatus,
    addBoss,
    removeBoss,
    increaseBossHealth,
    decreaseBossHealth,
    toggleBossStatus,
    newState,
  };
};

export default useMonster;
