export const allStatus = [
  "Poison",
  "Muddle",
  "Wound",
  "Invisible",
  "Immobilize",
  "Strengthen",
  "Disarm",
  "Stun",
];
