import styled from "@emotion/styled";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/esm/Card";
import FormSelect from "react-bootstrap/esm/FormSelect";
import ListGroup from "react-bootstrap/esm/ListGroup";

export const PageContent = styled.main`
  max-width: 1200px;
  padding: 0 16px;
  margin: auto;
`;

export const BoardStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-gap: 16px;

  justify-content: center;
  padding: initial;
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const RowCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ListItem = styled(ListGroup.Item)`
  padding-left: 0;
  padding-right: 0;
`;

export const FormSelectStyled = styled(FormSelect)`
  width: auto;
  margin-right: 16px;
`;

export const CardImg = styled(Card.Img)`
  min-height: 8rem;
  object-fit: cover;
  object-position: 100% 12%;
  opacity: 0.5;
`;

export const CardImgOverlay = styled.div`
  min-height: 8rem;
  position: sticky;
  z-index: 1;
  background: white;
  top: 0;
  display: flex;
  flex-direction: column;

  & > * {
    position: relative;
  }
  &::before {
    content: "";
    background-image: ${({ background }) => `url('${background}')`};
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.35;
  }
`;

export const Section = styled(RowContainer)`
  border-bottom: 1px solid #666;
  padding: 6px;
  margin: 0;
  width: 100%;
`;

export const Title = styled.p`
  font-weight: bold;
  margin: 0;
`;

export const GhostButton = styled(Button)`
  border: 0;
  width: 100%;
  margin-top: auto;
`;
