import React, { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { getNewSocketRoom } from "../api/socket";
import config from "../config";

const NewRoom = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const handleNewRoom = () => {
    setLoading(true);
    getNewSocketRoom().then((roomId) => {
      const gameRoomUrl = `${config.SOCKET.ROOM_PATH}/${roomId}`;
      history.push(gameRoomUrl);
    });
  };

  return (
    <div>
      <p>To start a new game room, press the button bellow:</p>
      <Button onClick={handleNewRoom} disabled={loading}>
        Start Room
      </Button>
    </div>
  );
};

export default NewRoom;
