import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { useMonsterContext } from "../hooks/monsterContext";

const Container = styled.div`
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`;

const AlertSocket = () => {
  const [show, setShow] = useState(false);
  const { isSocketOpen } = useMonsterContext();
  useEffect(() => {
    if (!isSocketOpen) {
      setShow(true);
    }
  }, [isSocketOpen]);

  return (
    show && (
      <Container>
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          Socket closed.
        </Alert>
      </Container>
    )
  );
};

export default AlertSocket;
