import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { initSocket } from "../api/socket";
import useMonster, {
  getBoss,
  getMonster,
  getMonsterType,
  jsonToState,
} from "./useMonster";

const EVENT_NAMES = {
  AddMonsterType: "AddMonsterType",
  AddMonster: "AddMonster",
  AddBoss: "AddBoss",
  RemoveMonsterType: "RemoveMonsterType",
  RemoveMonster: "RemoveMonster",
  RemoveBoss: "RemoveBoss",
  ChangeMonsterHealth: "ChangeMonsterHealth",
  ChangeBossHealth: "ChangeBossHealth",
  ToggleBossStatus: "ToggleBossStatus",
  ToggleMonsterStatus: "ToggleMonsterStatus",
  ChangeMonsterID: "ChangeMonsterID",
  Undo: "Undo",
};

const DEBOUNCE_TIME = 0;

export const useMonsterSocket = (roomId) => {
  const [monsterHealthAcc, setMonsterHealthAcc] = useState(0);
  const [bossHealthAcc, setBossHealthAcc] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [socket, setSocket] = useState(() => initSocket(roomId), []);
  const [isSocketOpen, setIsSocketOpen] = useState(true);

  const {
    state,
    addMonster: addMonsterReducer,
    removeMonster: removeMonsterReducer,
    increaseMonsterHealth: increaseMonsterHealthReducer,
    decreaseMonsterHealth: decreaseMonsterHealthReducer,
    toggleMonsterStatus: toggleMonsterStatusReducer,
    removeBoss: removeBossReducer,
    increaseBossHealth: increaseBossHealthReducer,
    decreaseBossHealth: decreaseBossHealthReducer,
    toggleBossStatus: toggleBossStatusReducer,
    newState,
  } = useMonster();

  useEffect(() => {
    socket.onclose = () => {
      setIsSocketOpen(false);
      setSocket(initSocket(roomId));
    };
    socket.onmessage = (ev) => {
      const data = JSON.parse(ev.data);
      const serverState = jsonToState(data);
      newState(serverState);
    };

    return () => {
      socket.close?.();
      setIsSocketOpen(true);
    };
  }, [roomId, socket]);

  const send = useCallback(
    (event, data) => {
      const message = JSON.stringify({ event, ...data });
      socket.send(message);
    },
    [socket]
  );

  /**
   * DEBOUNCED CALLBACKS
   */
  const debouncedSendChangeMonsterHealth = useDebouncedCallback(
    (monsterTypeId, monsterId) => {
      const monsterType = getMonsterType(state.monsterTypes, monsterTypeId);
      const monster = getMonster(state.monsterTypes, monsterTypeId, monsterId);
      if (monster) {
        send(EVENT_NAMES.ChangeMonsterHealth, {
          name: monsterType.name,
          elite: monsterType.elite,
          id: monster.id,
          change: monsterHealthAcc,
        });
        setMonsterHealthAcc(0);
      }
    },
    DEBOUNCE_TIME
  );
  const debouncedSendChangeBossHealth = useDebouncedCallback((bossId) => {
    const boss = getBoss(state.bosses, bossId);
    if (boss) {
      send(EVENT_NAMES.ChangeBossHealth, {
        name: boss.name,
        change: bossHealthAcc,
      });
      setBossHealthAcc(0);
    }
  }, DEBOUNCE_TIME);

  /**
   * SOCKET ACTIONS WITH REDUCER CALLS
   */
  const addMonsterType = useCallback(
    (newMonsterName, elite, level = 1) => {
      send(EVENT_NAMES.AddMonsterType, {
        name: newMonsterName,
        level: level,
        elite: elite,
      });
    },
    [send]
  );
  const removeMonsterType = useCallback(
    (monsterType) => {
      send(EVENT_NAMES.RemoveMonsterType, {
        name: monsterType.name,
        elite: monsterType.elite,
      });
      removeMonsterReducer(monsterType.id);
    },
    [removeMonsterReducer, send]
  );
  const addMonster = useCallback(
    (monsterType, id) => {
      const { name, elite } = monsterType;
      send(EVENT_NAMES.AddMonster, {
        name: name,
        elite: elite,
        id: id || 1,
      });
      addMonsterReducer(monsterType.id, id);
    },
    [addMonsterReducer, send]
  );
  const removeMonster = useCallback(
    (monsterType, monsterId) => {
      const { name, elite } = monsterType;
      send(EVENT_NAMES.RemoveMonster, {
        name: name,
        elite: elite,
        id: monsterId,
      });
      removeMonsterReducer(monsterType.id, monsterId);
    },
    [removeMonsterReducer, send]
  );
  const addBoss = useCallback(
    (newBossName, level, partySize) => {
      send(EVENT_NAMES.AddBoss, { name: newBossName, level, partySize });
    },
    [send]
  );
  const removeBoss = useCallback(
    (boss) => {
      send(EVENT_NAMES.RemoveBoss, { name: boss.name });
      removeBossReducer(boss.id);
    },
    [removeBossReducer, send]
  );
  const increaseMonsterHealth = useCallback(
    (monsterType, monster) => {
      increaseMonsterHealthReducer(monsterType.id, monster.id);
      setMonsterHealthAcc((prev) => prev + 1);
      debouncedSendChangeMonsterHealth(monsterType.id, monster.id);
    },
    [debouncedSendChangeMonsterHealth, increaseMonsterHealthReducer]
  );
  const decreaseMonsterHealth = useCallback(
    (monsterType, monster) => {
      decreaseMonsterHealthReducer(monsterType.id, monster.id);
      setMonsterHealthAcc((prev) => prev - 1);
      debouncedSendChangeMonsterHealth(monsterType.id, monster.id);
    },
    [debouncedSendChangeMonsterHealth, decreaseMonsterHealthReducer]
  );
  const increaseBossHealth = useCallback(
    (bossId) => {
      setBossHealthAcc((prev) => prev + 1);
      debouncedSendChangeBossHealth(bossId);
      increaseBossHealthReducer(bossId);
    },
    [debouncedSendChangeBossHealth, increaseBossHealthReducer]
  );
  const decreaseBossHealth = useCallback(
    (bossId) => {
      setBossHealthAcc((prev) => prev - 1);
      debouncedSendChangeBossHealth(bossId);
      decreaseBossHealthReducer(bossId);
    },
    [debouncedSendChangeBossHealth, decreaseBossHealthReducer]
  );
  const toggleMonsterStatus = useCallback(
    (monsterType, monsterId, statusName) => {
      toggleMonsterStatusReducer(monsterType.id, monsterId, statusName);
      send(EVENT_NAMES.ToggleMonsterStatus, {
        name: monsterType.name,
        elite: monsterType.elite,
        id: monsterId,
        status: statusName,
      });
    },
    [send, toggleMonsterStatusReducer]
  );

  const toggleBossStatus = useCallback(
    (boss, statusName) => {
      toggleBossStatusReducer(boss.id, statusName);
      send(EVENT_NAMES.ToggleBossStatus, {
        name: boss.name,
        status: statusName,
      });
    },
    [send, toggleBossStatusReducer]
  );

  const undo = useCallback(() => {
    send(EVENT_NAMES.Undo, {});
  }, [send]);

  return {
    state,
    isSocketOpen,
    addMonsterType,
    removeMonsterType,
    addMonster,
    removeMonster,
    increaseMonsterHealth,
    decreaseMonsterHealth,
    toggleMonsterStatus,
    addBoss,
    removeBoss,
    increaseBossHealth,
    decreaseBossHealth,
    toggleBossStatus,
    undo,
  };
};
