import React from "react";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <>
      <Header />
      <h1>Page Not found</h1>
    </>
  );
};

export default NotFound;
