import React from "react";
import { Route, Switch } from "react-router-dom";
import GameRoom from "./pages/GameRoom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

const ROUTES = [
  {
    path: "/",
    key: "APP_ROOT",
    exact: true,
    component: Home,
  },
  {
    path: "/room",
    key: "APP_ROOM_ROOT",
    exact: true,
    component: Home,
  },
  {
    path: "/room/:roomId",
    key: "APP_ROOM",
    exact: true,
    component: GameRoom,
  },
];

export const RenderRoutes = () => {
  return (
    <Switch>
      {ROUTES.map((route) => {
        return (
          <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={(renderProps) => <route.component {...renderProps} />}
          />
        );
      })}
      <Route component={NotFound} />
    </Switch>
  );
};
