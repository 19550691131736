import React, { useState } from "react";
import Card from "react-bootstrap/esm/Card";
import ListGroup from "react-bootstrap/esm/ListGroup";
import CloseButton from "react-bootstrap/esm/CloseButton";
import { useMonsterContext } from "../hooks/monsterContext";
import { allStatus } from "../utils/status";
import { Properties, BaseStats, Health, Status, ToggleButton } from "./Common";
import {
  CardImgOverlay,
  ListItem,
  RowCentered,
  RowContainer,
  Section,
  Title,
} from "./Common.styled";

export const Boss = ({ boss }) => {
  const {
    removeBoss,
    increaseBossHealth,
    decreaseBossHealth,
    toggleBossStatus,
  } = useMonsterContext();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {
    id,
    name,
    maxHealth,
    health,
    attack,
    move,
    range,
    portrait,
    attributes,
    immunities,
    specials,
    status,
  } = boss;

  return (
    <Card>
      <CardImgOverlay className="card-img-top" background={portrait}>
        <Section>
          <RowContainer>
            <Title>{name}</Title>
            <CloseButton onClick={() => removeBoss(boss)} />
          </RowContainer>
        </Section>
        <Section className="card-title h5">
          <BaseStats
            health={maxHealth}
            attack={attack}
            move={move}
            range={range}
          />
        </Section>
        <Section>
          <Properties properties={attributes} />
          <Properties title="Immunities" properties={immunities} />
        </Section>
        <ToggleButton onClick={() => setIsCollapsed(!isCollapsed)} />
      </CardImgOverlay>
      {!isCollapsed && (
        <Card.Body>
          <ListGroup variant="flush">
            <ListItem>
              <RowCentered>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Health
                    health={health}
                    onIncreaseHealth={() => increaseBossHealth(id)}
                    onDecreaseHealth={() => decreaseBossHealth(id)}
                  />{" "}
                </div>
              </RowCentered>
              <RowCentered>
                {allStatus
                  .filter((s) => !immunities.some((i) => i.name === s))
                  .map((s) => (
                    <Status
                      name={s}
                      key={s}
                      isActive={status.has(s)}
                      onToggleStatus={() => toggleBossStatus(boss, s)}
                    />
                  ))}
              </RowCentered>
            </ListItem>
          </ListGroup>
          <hr />
          {specials?.length > 0 && (
            <ListGroup variant="flush">
              <ListItem>
                {specials.map((s, i) => (
                  <div key={s}>
                    <strong>Special {i + 1}</strong>: {s.join(". ")}
                  </div>
                ))}
              </ListItem>
            </ListGroup>
          )}
        </Card.Body>
      )}
    </Card>
  );
};
