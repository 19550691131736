import React from "react";
import { PageContent } from "../components/Common.styled";
import Header from "../components/Header";
import LogToRoom from "../components/LogToRoom";
import NewRoom from "../components/NewRoom";

const Home = (props) => {
  return (
    <>
      <Header />
      <PageContent>
        <NewRoom {...props} />
        <hr />
        <LogToRoom {...props} />
      </PageContent>
    </>
  );
};

export default Home;
