import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {Table} from "react-bootstrap";

const ScenarioLevelModal = ({show, onClose}) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Scenario level</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover>
          <thead>
          <tr>
            <td>Level</td>
            <td>Gold</td>
            <td>Trap damage</td>
            <td>Experience</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>0</td>
            <td>2</td>
            <td>2</td>
            <td>4</td>
          </tr>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>6</td>
          </tr>
          <tr>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>8</td>
          </tr>
          <tr>
            <td>3</td>
            <td>3</td>
            <td>5</td>
            <td>10</td>
          </tr>
          <tr>
            <td>4</td>
            <td>4</td>
            <td>6</td>
            <td>12</td>
          </tr>
          <tr>
            <td>5</td>
            <td>4</td>
            <td>7</td>
            <td>14</td>
          </tr>
          <tr>
            <td>6</td>
            <td>5</td>
            <td>8</td>
            <td>16</td>
          </tr>
          <tr>
            <td>7</td>
            <td>6</td>
            <td>9</td>
            <td>18</td>
          </tr>
          </tbody>
        </Table>

      </Modal.Body>
    </Modal>
  );
};

const ScenarioLevel = () => {
  const [showModal, setShowModal] = useState(false);

  return (
      <>
        <Button className="me-4" onClick={() => setShowModal(true)}>Scenario Level</Button>
        <ScenarioLevelModal
            show={showModal}
            onClose={() => setShowModal(false)}
        />
      </>
  );
};

export default ScenarioLevel;