import React from "react";
import { useMonsterContext } from "../hooks/monsterContext";
import Button from "react-bootstrap/Button";

const Undo = () => {
  const { undo } = useMonsterContext();
  return (
    <>
      <Button className="me-4" onClick={undo}>
        Undo
      </Button>
    </>
  );
};

export default Undo;
