import React from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import config from "../config";

const LogToRoom = ({ history }) => {
  const handleGoToRoom = (e) => {
    e?.preventDefault();
    const roomId = e.target.roomId.value;
    const gameRoomUrl = `${config.SOCKET.ROOM_PATH}/${roomId}`;
    history.push(gameRoomUrl);
  };

  return (
    <Form onSubmit={handleGoToRoom}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Room Id</Form.Label>
        <Form.Control name="roomId" type="text" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default LogToRoom;
