const config = {
  API_URL: process.env.REACT_APP_API_URL,
  SEARCH_MONSTER_PATH: "/api/monster",
  SEARCH_BOSS_PATH: "/api/boss",
  SEARCH_ALL_PATH: "/api/all",
  GET_MONSTER_PATH: "/api/monster/specific",
  GET_BOSS_PATH: "/api/boss/specific",
  GET_SCENARIO_PATH: (id) => `/api/scenario/${id}/names`,
  NEW_ROOM_PATH: `/room`, //This is a websocket route
  ROOM_PATH: (roomKey) => `/room/${roomKey}`, //This is a websocket route
  ROOM_SHARE_PATH: (roomKey) => `/room/share/${roomKey}`,
  SOCKET: {
    API_URL: process.env.REACT_APP_SOCKET_API_URL,
    ROOM_PATH: "/room",
  },
};

export default config;
