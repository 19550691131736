import React from "react";
import AddMonster from "./AddMonster";
import Undo from "./Undo";
import Header from "./Header";
import ScenarioLevel from "./ScenarioLevel";

const GameHeader = () => {
  return (
    <Header>
      <div>
        <ScenarioLevel />
        <Undo />
        <AddMonster />
      </div>
    </Header>
  );
};

export default GameHeader;
