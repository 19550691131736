import config from "../../config";

export const initSocket = (roomId) => {
  const socketUrl = `${config.SOCKET.API_URL}${config.SOCKET.ROOM_PATH}/${
    roomId ?? ""
  }`;
  try {
    return new WebSocket(socketUrl);
  } catch (err) {
    alert("Failed to load room. " + err);
    return null;
  }
};

export const getNewSocketRoom = () => {
  return new Promise((resolve, reject) => {
    const socketUrl = `${config.SOCKET.API_URL}${config.SOCKET.ROOM_PATH}`;
    try {
      const newSocket = new WebSocket(socketUrl);
      console.log(newSocket);
      newSocket.addEventListener("message", function (event) {
        console.log("Message from server ", event.data);
        const data = JSON.parse(event.data);
        console.log("data", data);
        if (data.id) {
          newSocket.close();
          resolve(data.id);
        } else {
          reject(`Unable to retrieve room id. Data received: ${data}`);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
