import React, { useState } from "react";
import Card from "react-bootstrap/esm/Card";
import ListGroup from "react-bootstrap/esm/ListGroup";
import { useMonsterContext } from "../hooks/monsterContext";
import CloseButton from "react-bootstrap/esm/CloseButton";
import { allStatus } from "../utils/status";
import {
  ActionButton,
  Properties,
  BaseStats,
  GameId,
  Health,
  Status,
  SelectGameId,
  ToggleButton,
} from "./Common";
import {
  CardImgOverlay,
  ListItem,
  RowCentered,
  RowContainer,
  Section,
  Title,
} from "./Common.styled";
import { sortByGameId, sortStringAsNumber } from "../utils/sort";

export const Monster = ({ monsterType, monster, typeId }) => {
  const { id, gameId, health, status } = monster;
  const {
    removeMonster,
    increaseMonsterHealth,
    decreaseMonsterHealth,
    toggleMonsterStatus,
  } = useMonsterContext();

  return (
    <>
      <RowContainer>
        <div>
          <GameId gameId={gameId} />
        </div>
        <div>
          <Health
            health={health}
            onIncreaseHealth={() => increaseMonsterHealth(monsterType, monster)}
            onDecreaseHealth={() => decreaseMonsterHealth(monsterType, monster)}
          />{" "}
        </div>

        <div>
          <ActionButton
            variant="outline-secondary"
            size="sm"
            onClick={() => removeMonster(monsterType, monster.id)}
          >
            X
          </ActionButton>
        </div>
      </RowContainer>
      <RowCentered>
        {allStatus.map((s) => (
          <Status
            name={s}
            key={s}
            isActive={status.has(s)}
            onToggleStatus={() => toggleMonsterStatus(monsterType, id, s)}
          />
        ))}
        <Status
          name={"Summon"}
          isActive={status.has("Summon")}
          imgName="summon/purple.png"
          onToggleStatus={() => toggleMonsterStatus(monsterType, id, "Summon")}
        />
      </RowCentered>
    </>
  );
};

const MonsterType = ({ monsterType }) => {
  const { addMonster, removeMonsterType } = useMonsterContext();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {
    id,
    name,
    health,
    attack,
    move,
    range,
    elite,
    portrait,
    attributes,
    monsters,
  } = monsterType;

  return (
    <Card className={elite ? "card-elite" : ""}>
      <CardImgOverlay className="card-img-top" background={portrait}>
        <Section>
          <Title>
            {name} - {elite ? "Elite" : "Normal"}
          </Title>
          <CloseButton onClick={() => removeMonsterType(monsterType)} />
        </Section>
        <Section className="card-title h5">
          <BaseStats
            health={health}
            attack={attack}
            move={move}
            range={range}
          />
        </Section>
        {attributes.length > 0 && (
          <Section>
            <Properties properties={attributes} />
          </Section>
        )}
        <ToggleButton onClick={() => setIsCollapsed(!isCollapsed)} />
      </CardImgOverlay>

      {!isCollapsed && (
        <Card.Body>
          {monsters.length > 0 && (
            <>
              <ListGroup variant="flush">
                {monsters.sort(sortByGameId).map((m) => (
                  <ListItem key={m.id}>
                    <Monster
                      monsterType={monsterType}
                      monster={m}
                      typeId={id}
                    />
                  </ListItem>
                ))}
              </ListGroup>
              <hr />
            </>
          )}
          <RowCentered>
            <AddNewMonsterById
              monsterType={monsterType}
              addMonster={addMonster}
            />
          </RowCentered>
        </Card.Body>
      )}
    </Card>
  );
};

const AddNewMonsterById = ({ monsterType, addMonster }) => {
  const { possibleIDs } = monsterType;
  if (possibleIDs.length === 0) return null;
  return (
    <>
      <SelectGameId
        idsList={possibleIDs.sort(sortStringAsNumber)}
        onGameIdChange={(newId) => {
          if (newId > 0) {
            addMonster(monsterType, newId);
          }
        }}
      />
    </>
  );
};

export default MonsterType;
