import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import config from "../config";
import { useMonsterContext } from "../hooks/monsterContext";

const fetchJson = (url) => fetch(url).then((resp) => resp.json());

const fetchMonster = (name) =>
  fetchJson(`${config.API_URL}${config.SEARCH_MONSTER_PATH}/${name}`);

const fetchBoss = (name) =>
  fetchJson(`${config.API_URL}${config.SEARCH_BOSS_PATH}/${name}`);

const fetchScenario = (id) =>
  fetchJson(`${config.API_URL}${config.GET_SCENARIO_PATH(id)}`);

const MonsterModal = ({ show, onClose, onAddMonsterType, onAddBoss }) => {
  const [bossResults, setBossResults] = useState([]);
  const [monsterResults, setMonsterResults] = useState([]);
  const [level, setLevel] = useState(1);
  const [numChars, setNumChars] = useState(4);

  const addBoss = (name) => {
    fetchBoss(name).then((newBoss) => {
      setBossResults((prevState) =>
        !prevState.some((b) => b.name === newBoss.name)
          ? [...prevState, newBoss]
          : prevState
      );
    });
  };
  const addMonster = (name) => {
    fetchMonster(name).then((newMonster) => {
      setMonsterResults((prevState) =>
        !prevState.some((m) => m.name === newMonster.name)
          ? [...prevState, newMonster]
          : prevState
      );
    });
  };

  const addBossAndMonster = (name) => {
    addBoss(name);
    addMonster(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.monsterName.value;
    addBossAndMonster(name);
  };

  const handleScenarioSubmit = (e) => {
    e.preventDefault();
    const scenarioID = e.target.scenarioID.value;
    fetchScenario(scenarioID).then((allNames) => {
      allNames.monsters.forEach(addMonster);
      allNames.bosses.forEach(addBoss);
    });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Monster</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group as={Row}>
            <Col>
              <Form.Label column>Level</Form.Label>
              <Form.Control
                xs="auto"
                name="level"
                type="number"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label column>Num Characters</Form.Label>
              <Form.Control
                xs="auto"
                name="chars"
                type="number"
                value={numChars}
                onChange={(e) => setNumChars(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Form>
        <hr />
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Control
                name="monsterName"
                type="text"
                placeholder="Monster Name"
              />
            </Col>

            <Col xs="auto">
              <Button variant="primary" type="submit">
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <hr />
        <Form onSubmit={handleScenarioSubmit}>
          <Row>
            <Col>
              <Form.Control
                name="scenarioID"
                type="number"
                placeholder="Scenario ID"
              />
            </Col>

            <Col xs="auto">
              <Button variant="primary" type="submit">
                Fill
              </Button>
            </Col>
          </Row>
        </Form>

        <hr />
        <Row>
          <Col>
            <ListGroup variant="flush">
              {bossResults.length > 0 &&
                bossResults.map((bossResult) => (
                  <ListGroup.Item key={bossResult.name}>
                    <Row>
                      <Col>{bossResult.name}</Col>
                      <Col xs="auto">
                        <Button
                          onClick={() =>
                            onAddBoss({
                              name: bossResult.name,
                              level,
                              numChars,
                            })
                          }
                        >
                          Add Boss
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              {monsterResults.length > 0 &&
                monsterResults.map((monsterResult) => (
                  <ListGroup.Item key={monsterResult.name}>
                    <Row>
                      <Col>{monsterResult.name}</Col>
                      {monsterResult.normal && (
                        <Col xs="auto">
                          <Button
                            onClick={() =>
                              onAddMonsterType({
                                name: monsterResult.name,
                                level,
                                elite: false,
                              })
                            }
                          >
                            Normal
                          </Button>
                        </Col>
                      )}
                      {monsterResult.elite && (
                        <Col xs="auto">
                          <Button
                            onClick={() =>
                              onAddMonsterType({
                                name: monsterResult.name,
                                level,
                                elite: true,
                              })
                            }
                          >
                            Elite
                          </Button>
                        </Col>
                      )}
                      {!monsterResult.normal && !monsterResult.elite && (
                        <Col xs="auto">
                          <Button
                            onClick={() =>
                              onAddMonsterType({
                                name: monsterResult.name,
                                level,
                                elite: false,
                              })
                            }
                          >
                            Add
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const AddMonster = () => {
  const [showModal, setShowModal] = useState(false);
  const { addMonsterType, addBoss } = useMonsterContext();
  const handleAddMonsterType = ({ name, elite, level }) => {
    addMonsterType(name, elite, level);
  };
  const handleAddBoss = ({ name, level, numChars }) => {
    addBoss(name, level, numChars);
  };
  return (
    <>
      <Button onClick={() => setShowModal(true)}>Add Monster</Button>
      <MonsterModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onAddMonsterType={handleAddMonsterType}
        onAddBoss={handleAddBoss}
      />
    </>
  );
};

export default AddMonster;
