import React, { useMemo } from "react";
import { useMonsterContext } from "../hooks/monsterContext";
import { sortMonsterType } from "../utils/sort";
import { Boss } from "./Boss";
import { BoardStyled } from "./Common.styled";
import MonsterType from "./Monster";

const MonstersBoard = () => {
  const {
    state: { monsterTypes, bosses },
  } = useMonsterContext();

  const allMonsters = useMemo(
    () => [
      ...bosses.map((b) => ({ type: "boss", monster: b })),
      ...monsterTypes
        .sort(sortMonsterType)
        .map((m) => ({ type: "monster", monster: m })),
    ],
    [bosses, monsterTypes]
  );

  return (
    <BoardStyled className="container">
      {allMonsters?.map(({ type, monster }) =>
        type === "boss" ? (
          <Boss key={monster.name} boss={monster} />
        ) : (
          <MonsterType
            monsterType={monster}
            key={monster.name + monster.elite}
          />
        )
      )}
    </BoardStyled>
  );
};

export default MonstersBoard;
