import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/esm/Navbar";

const Header = ({ children }) => {
  return (
    <header>
      <div className="py-2 bg-dark text-white">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <Navbar bg="dark" variant="dark" className="p-0">
              <Link to="/" className="fs-4 nav-link p-0">
                <Navbar.Brand>
                  <svg
                    className="bi me-2"
                    width="40"
                    height="32"
                    role="img"
                    aria-label="Book"
                  >
                    <use href="/assets/bootstrap-icons/bootstrap-icons.svg#book"></use>
                  </svg>
                  Gloomhaven Tracker
                </Navbar.Brand>
              </Link>
            </Navbar>
            {children}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
