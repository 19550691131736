import React from "react";
import GameHeader from "../components/GameHeader";
import MonstersBoard from "../components/MonstersBoard";
import { MonsterContextProvider } from "../hooks/monsterContext";
import { useParams } from "react-router";
import AlertSocket from "../components/AlertSocket";

function GameRoom() {
  const { roomId } = useParams();
  return (
    <MonsterContextProvider roomId={roomId}>
      <GameHeader />
      <MonstersBoard />
      <AlertSocket />
    </MonsterContextProvider>
  );
}

export default GameRoom;
